import Login from '../pages/login'
import MainMap from '../pages/main_map'
import MainDashboard from '../pages/main_dashboard'
import MainReport from '../pages/main_report'
import MainDrivers from '../pages/main_drivers'
import MainCar_list from '../pages/main_car_list'
import MainPark_settings from '../pages/main_park_settings'
import Privacy_policy from '../pages/privacy_policy'
import ShareMap from '../pages/ShareMap'
import Privacy_policy_spb from '../pages/privacy_policy_spb'

export const privateRoutes = [
  { path: '/share', component: ShareMap, exact: true },
  { path: '/map', component: MainMap, exact: true },
  { path: '/dashboard', component: MainDashboard, exact: true },
  { path: '/report', component: MainReport, exact: true },
  { path: '/drivers_list', component: MainDrivers, exact: true },
  { path: '/car_list', component: MainCar_list, exact: true },
  { path: '/park_settings', component: MainPark_settings, exact: true },
  { path: '/privacy_policy', component: Privacy_policy, exact: true },
  { path: '/privacy_policy_spb', component: Privacy_policy_spb, exact: true },
  { path: '/', component: MainMap, exact: true },
  { path: '*', component: MainMap, exact: true },
]

export const publicRoutes = [
  { path: '/share', component: ShareMap, exact: true },
  { path: '/auth', component: Login, exact: true },
  { path: '/privacy_policy', component: Privacy_policy, exact: true },
  { path: '/privacy_policy_spb', component: Privacy_policy_spb, exact: true },
  { path: '/', component: Login, exact: true },
  { path: '*', component: Login, exact: true },
]
